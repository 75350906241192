.element {
  animation: blinker 1.5s linear infinite;
  cursor: pointer;
  margin-right: 1em;
  color: orange;
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}
